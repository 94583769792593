import { PhotoSharp, YouTube, Launch} from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";

export const HomeJumbo = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { file } = useStaticQuery(graphql`
    query {
      file(name: { eq: "logo-fede" }) {
        childImageSharp {
          gatsbyImageData(backgroundColor: "#fff")
        }
      }
    }
  `);
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.home.jumbo",
  });

  return (
    <>
      <div className="jumbo-home-blood">
        <img src="/images/jumbo-blood.png" alt="background" />
      </div>

      <div>
        <Typography variant="h1" textAlign="center">
          {t("come-back")}
        </Typography>
        <Typography variant="h1" textAlign="center">
          {t("date")}
        </Typography>
        {/*<Typography*/}
        {/*  variant="h2"*/}
        {/*  textAlign="center"*/}
        {/*  style={{marginTop: "10px", marginBottom: "25px"}}*/}
        {/*>*/}
        {/*  Code, Dev, et... Action !*/}
        {/*</Typography>*/}
        <Stack direction="column" spacing={3}>
          {/*<Stack direction="row" spacing={3} justifyContent={"center"}>*/}
          {/*  <Button*/}
          {/*    color="secondary"*/}
          {/*    variant="contained"*/}
          {/*    href="https://devfest2022.gdgnantes.com"*/}
          {/*    aria-label={t("previous")}*/}
          {/*  >*/}
          {/*    {t("previous")}*/}
          {/*  </Button>*/}
          {/*</Stack>*/}
          <Stack direction="row" spacing={3} justifyContent={"center"}>
            {/* <Button
              color="primary"
              variant="contained"
              href="https://conference-hall.io/48-rencontres-lgbti"
              aria-label={t("cfp")}
            >
              {t("cfp")}
            </Button> */}
            <Button
              color="primary"
              variant="contained"
              href="mailto:contact@federation-lgbti.org"
              aria-label="Relation Presse"
            >
              Relations presse
            </Button>
            <Button
              color="primary"
              variant="contained"
              aria-label="Billetterie"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfZjyw-codX3NH8DWDPPaCLRyMvQNVKghaHoL6slQKPm3hcfQ/viewform?usp=sf_link"
            >
              Venir aux rencontres
            </Button>
          </Stack>
          <Stack direction="row" spacing={3} justifyContent={"center"}>

            {/*
          <Button
            color="primary"
            variant="contained"
            href="https://billetterie.gdgnantes.com"
            aria-label="Send tickets"
            target={"_blank"}
          >
            {t('resend-tickets')}
          </Button>
          <Button
          color="primary"
          variant="outlined"
          onClick={() => setModalOpen(true)}
          aria-label="Send tickets"
          >
          {t('get-app')}
        </Button> */}
            <Button
              color="primary"
              startIcon={<Launch />}
              variant="contained"
              href="https://federation-lgbti.org/"
              aria-label="Videos 2023"
              target={"_blank"}
            >
              Fédération LGBTI+
            </Button>
            <Button
              color="primary"
              startIcon={<PhotoSharp />}
              variant="contained"
              href="https://photos.app.goo.gl/yGkkq2jQqDMmzgVdA"
              aria-label="Photos 2023"
              target={"_blank"}
            >
              Photos
            </Button>
            <GatsbyImage
              image={getImage(file) as IGatsbyImageData}
              alt="Logo Federation"
              style={{ maxHeight: "76px" }}
             />
          </Stack>
          {/* <Stack direction="row" spacing={3} justifyContent={"center"}>
        </Stack> */}
        </Stack>
      </div>

    </>
  );
};
