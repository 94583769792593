import { Grid, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { MyLink } from "../../helpers/links";

export const Tickets = () => {
  const disabled1st = false;
  const disabled2nd = true;

  return (
    <Grid
      container
      columnSpacing={1}
      rowSpacing={2}
      margin="20px 0"
      justifyContent="center"
      className="tickets"
    >
      <Ticket
        labelKey="2days-2nd"
        price={52}
        quantity={100}
        date="08/2024"
        />
      <Ticket
        labelKey="1day"
        price={35}
        quantity={50}
        date="08/2024"
      />
    </Grid>
  );
};

const Ticket: React.FC<{
  price: number;
  labelKey: string;
  date: string;
  quantity: number;
  disabled: boolean;
}> = ({ labelKey, price, quantity, disabled, date }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.home.tickets",
  });

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <MyLink
        to="https://federation-lgbti.assoconnect.com/collect/description/448477-b-48e-rencontres-lgbti-d-ete-24-25-aout-2024-angers-participation-aux-frais"
        style={{ cursor: "default" }}
      >
        <div className={classNames("ticket", disabled && "disabled")}>
          <div className="ticket-wrapper">
            <div className="ticket-body">
              <div className="price">
                <Typography variant="h2">{price} € <span style={{fontSize: "8px"}}></span></Typography>
                <hr />
              </div>
              <div className="description">
                <p className="label">{t(labelKey)}</p>
                <p className="quantity">{quantity} places</p>
                <p className="date">{date}</p>
              </div>
            </div>
          </div>
        </div>
      </MyLink>
    </Grid>
  );
};
